<template>
  <v-app-bar id="core-app-bar" absolute app color="transparent" flat>
    <div class="contents-inner">
      <v-toolbar-title class="tertiary--text align-self-center">
        <v-btn v-if="responsive" icon @click.stop="onClick">
          <v-icon>mdi-view-list</v-icon>
        </v-btn>
        <span v-if="title">{{ $t('menu.' + title) }}</span>
      </v-toolbar-title>

      <v-spacer />

      <v-toolbar-items>
        <v-row align="center" class="mx-0 d-none d-md-flex">
          <v-slide-x-transition group>
            <template v-for="(button, index) in buttons">
              <v-btn
                v-if="!button.hidden && !button.pullDownList"
                :key="index"
                :color="button.color"
                :disabled="button.disabled === true"
                :outlined="button.outlined"
                class="px-n1 mx-1"
                :class="button.class"
                rounded
                @click.stop="button.click ? button.click() : null"
              >
                <v-icon>{{ button.icon }}</v-icon>
                <span class="d-none d-md-flex">{{ button.text }}</span>
              </v-btn>
              <v-menu
                v-else-if="!button.hidden && button.pullDownList"
                :key="index"
              >
                <template #activator="{ on }">
                  <v-btn
                    :key="index"
                    :color="button.color"
                    :disabled="button.disabled === true"
                    :outlined="button.outlined"
                    class="px-n1 mx-1"
                    :class="button.class"
                    rounded
                    v-on="on"
                  >
                    <v-icon>{{ button.icon }}</v-icon>
                    <span class="d-none d-md-flex">{{ button.text }}</span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(table, pullDown_i) in button.pullDownList"
                    :key="pullDown_i"
                    :input-value="table.active"
                    @click.stop="table.click ? table.click() : null"
                  >
                    <v-list-item-title>
                      {{ $t(table.title) }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-slide-x-transition>
        </v-row>
      </v-toolbar-items>
    </div>
  </v-app-bar>
</template>

<script>
// Utilities
import { mapActions } from 'vuex'

export default {
  props: {},
  data: () => ({
    title: null,
    responsive: false,
    buttons: [],
  }),
  watch: {
    $route(val) {
      this.title = val.name
      this.buttons = []
    },
  },
  created() {
    this.setListener()
  },
  mounted() {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    this.title = this.$route.name
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  methods: {
    ...mapActions('app', ['doSetDrawer']),
    setListener() {
      // emitで発火させたイベント名にする
      this.$nuxt.$on('set-app-btn', this.setAppBtn)
    },
    setAppBtn(_btn) {
      this.buttons = _btn
    },
    onClick() {
      this.doSetDrawer(!this.$store.state.app.drawer)
    },
    onResponsiveInverted() {
      if (window.innerWidth < 1180) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    },
  },
}
</script>

<style>
/* Fix coming in v2.0.8 */
#core-app-bar {
  width: auto;
}

#core-app-bar a {
  text-decoration: none;
}
</style>
