<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel">
    <v-card>
      <v-card-title class="pa-0">
        <v-app-bar dense dark :class="options.color">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-app-bar>
      </v-card-title>
      <v-card-text v-show="message" class="py-5">
        <h3 v-html="message"></h3>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn :color="options.color" class="white--text" @click.stop="agree">
          はい
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'gray',
      width: 290,
    },
  }),
  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.dialog = false
    },
  },
}
</script>
