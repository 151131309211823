import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":_vm.options.width},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel($event)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"pa-0"},[_c(VAppBar,{class:_vm.options.color,attrs:{"dense":"","dark":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.title))])],1)],1),_vm._v(" "),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(_vm.message),expression:"message"}],staticClass:"py-5"},[_c('h3',{domProps:{"innerHTML":_vm._s(_vm.message)}})]),_vm._v(" "),_c(VCardActions,{staticClass:"pt-0"},[_c(VSpacer),_vm._v(" "),_c(VBtn,{staticClass:"white--text",attrs:{"color":_vm.options.color},on:{"click":function($event){$event.stopPropagation();return _vm.agree($event)}}},[_vm._v("\n        はい\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }