import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c('core-app-bar'),_vm._v(" "),_c('core-drawer'),_vm._v(" "),_c('core-view'),_vm._v(" "),_c('core-footer'),_vm._v(" "),_c(VDialog,{attrs:{"persistent":"","max-width":"50%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"color":"secondary","dark":""}},[(_vm.dialog)?_c(VCardText,[_vm._v("\n        "+_vm._s(_vm.dialog)+"\n        "),_c(VProgressLinear,{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1):_vm._e()],1)],1),_vm._v(" "),_c(VSnackbar,{attrs:{"max-width":800},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"color":_vm.snackbarType,"text":""},on:{"click":_vm.closeSnackbar}},'v-btn',attrs,false),[_vm._v("\n        Close\n      ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[(_vm.snackbarType === 'error')?_c(VIcon,{attrs:{"color":"error"}},[_vm._v("mdi-alert-circle")]):_vm._e(),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.snackbarMessage)}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }