<template>
  <v-navigation-drawer
    id="app-drawer"
    class="aside-menu"
    v-model="inputValue"
    app
    floating
    mobile-breakpoint="1180"
    persistent
  >
    <div class="aside-menu-inner">
      <!-- Logo -->
      <div class="logo">
        <img src="/img/logo/horizontal.svg" alt="" />
      </div>

      <!-- UserInfo -->
      <div class="user-info">
        <v-list-item>
          {{ user.username }}さん
          <!-- <v-spacer />
          <v-icon @click="(dialog = false), clearInput()">
            mdi-account-lock
          </v-icon>
          <v-icon class="ml-3" @click=";(dialog = false), clearInput()">
            mdi-email
          </v-icon> -->
        </v-list-item>
      </div>

      <!-- MenuList -->
      <div class="menu-list">
        <v-list nav>
          <!-- Bug in Vuetify for first child of v-list not receiving proper border-radius -->
          <div />
          <template v-for="(link, index) in links">
            <template v-if="link.show === undefined ? true : link.show">
              <Navbar :link="link" :key="index" v-if="link.group" />
              <v-list-item
                v-else-if="checkShowMenu(link.to)"
                :key="index"
                :to="link.click ? null : link.to"
                :input-value="checkActiveDrawer(link.to)"
                @click.stop="link.click ? link.click() : null"
              >
                <v-list-item-action>
                  <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-action>

                <v-list-item-title>
                  {{ link.text }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </template>
        </v-list>
      </div>

      <!-- password input-->
      <v-container container-fluid>
        <v-row>
          <v-col cols="12" md="6" offset="0" offset-md="3">
            <v-dialog v-model="dialog" :max-width="options.width">
              <validation-observer ref="obs" v-slot="{ handleSubmit, invalid }">
                <form @submit.prevent="handleSubmit()">
                  <v-card>
                    <v-app-bar dark dense color="primary">
                      <v-toolbar-title>
                        {{ $t('maintenance.edit.password.title') }}
                      </v-toolbar-title>
                      <v-spacer />
                      <v-btn icon @click="cancel">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-app-bar>
                    <v-card-text class="pa-md-10">
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{ required: true, max: 256 }"
                        :name="$t('maintenance.edit.password.old_password')"
                      >
                        <v-text-field
                          id="oldPassword"
                          v-model="oldPassword"
                          :label="$t('maintenance.edit.password.old_password')"
                          name="old_password"
                          autocomplete="current-password"
                          prepend-icon="mdi-lock"
                          type="password"
                          placeholder="Password"
                          maxlength="256"
                          :error-messages="errors"
                        />
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{
                          required: true,
                          max: 256,
                          min: 8,
                          uppercase: true,
                          lowercase: true,
                          number: true,
                          special_char: true,
                        }"
                        :name="$t('maintenance.edit.password.new_password')"
                      >
                        <v-text-field
                          id="newPassword"
                          v-model="newPassword"
                          :label="$t('maintenance.edit.password.new_password')"
                          name="new_password"
                          autocomplete="new-password"
                          prepend-icon="mdi-lock"
                          type="password"
                          placeholder="Password"
                          maxlength="256"
                          :error-messages="errors"
                        />
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{
                          required: true,
                          max: 256,
                          min: 8,
                          uppercase: true,
                          lowercase: true,
                          number: true,
                          special_char: true,
                          is: newPassword,
                        }"
                        :name="
                          $t('maintenance.edit.password.confirmed_password')
                        "
                      >
                        <v-text-field
                          id="confirmedPassword"
                          v-model="confirmedPassword"
                          :label="
                            $t('maintenance.edit.password.confirmed_password')
                          "
                          name="confirmed_password"
                          autocomplete="new-password"
                          prepend-icon="mdi-lock"
                          type="password"
                          placeholder="Password"
                          maxlength="256"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-card-text>
                    <v-card-actions class="mt-5">
                      <v-btn
                        color="primary darken-1"
                        :disabled="invalid"
                        @click="changePassword()"
                      >
                        変更
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </form>
              </validation-observer>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
      <!-- End Password input-->

      <notification ref="notification" />
    </div>
    <v-expand-transition>
      <v-expansion-panels
        v-if="showNotification && news.newsSize != 0"
        accordion
        class="notification"
      >
        <template v-for="(newsCategory, type) in news">
          <v-expansion-panel :key="type" v-if="newsCategory.length">
            <v-expansion-panel-header>
              <div class="d-flex" style="width: 90%">
                <span
                  class="px-2 label mr-1"
                  :style="{ color: newsCategory[0].item2 }"
                  >{{ $t(`news.${type}`) }}</span
                >
                <span class="noti-content mr-2">{{
                  newsCategory[0].news
                }}</span>
              </div>
              <span
                class="text-right flex-shrink-0"
                style="font-size: 13px; color: darkgreen"
                >{{ newsCategory.length }}{{ $t('news.counter') }}</span
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content
              v-for="(item, index) in newsCategory.slice(1)"
              :key="`new-${type}-${index}`"
            >
              <div class="d-flex">
                <span
                  class="px-2 label mr-1"
                  :style="{ color: newsCategory[0].item2 }"
                  >{{ $t(`news.${type}`) }}
                </span>
                <span class="pr-1 noti-content">{{ item.news }}</span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>
    </v-expand-transition>
    <portal to="btn-noti">
      <v-btn
        v-if="!initModeFlag && news.newsSize != 0"
        fab
        dark
        fixed
        left
        bottom
        color="#ed9a01"
        @click="TOGGLE_NOTIFICATION"
        class="btn-fab"
        :title="$t('news.news')"
      >
        <v-icon class="ringing font-zise-bell">mdi-bell-ring</v-icon>
      </v-btn>
    </portal>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import notification from '@/components/common/dialogs/Notification.vue'
import Navbar from './Navbar.vue'

export default {
  components: {
    notification,
    Navbar,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // for password edit
      dialog: false,
      options: {
        color: 'secondary',
        width: '60%',
      },
      oldPassword: '',
      newPassword: '',
      confirmedPassword: '',
      initModeFlag: true,
    }
  },
  async created() {
    await this.getNews()
    this.initModeFlag = false
  },
  computed: {
    ...mapState('codes', ['userInfo', 'settings']),
    ...mapState('app', ['user', 'showNotification', 'news']),
    ...mapGetters('app', ['checkShowMenu']),
    inputValue: {
      get() {
        return this.$store.state.app.drawer
      },
      set(val) {
        this.doSetDrawer(val)
      },
    },
    links: {
      get: function () {
        const _links = []
        _links.push(
          {
            to: '/dashboard',
            icon: 'mdi-home-analytics',
            text: this.$i18n.t('menu.dashboard'),
          },
          // {
          //   to: '/dashboard2',
          //   icon: 'mdi-home-analytics',
          //   text: this.$i18n.t('menu.dashboard') + 'TEST',
          // },
          {
            to: '/activity/input',
            icon: 'mdi-chart-box-plus-outline',
            text: this.$i18n.t('menu.activity-input'),
          },
          {
            group: true,
            title: this.$i18n.t('menu.setting'),
            icon: 'mdi-cog',
            show: this.checkShowMenu('/setting'),
            items: [
              {
                to: '/setting/organization',
                icon: 'mdi-sitemap',
                text: this.$i18n.t('menu.setting-organization'),
              },
              {
                to: '/setting/company',
                icon: 'mdi-office-building',
                text: this.$i18n.t('menu.setting-company'),
              },
              {
                to: '/setting/fiscal-year',
                icon: 'mdi-calendar-month',
                text: this.$i18n.t('menu.setting-fiscal-year'),
              },
              {
                to: '/setting/user',
                icon: 'mdi-account-group',
                text: this.$i18n.t('menu.setting-user'),
              },
              {
                to: '/setting/industry-templates-master',
                icon: 'mdi-format-list-bulleted-square',
                text: this.$i18n.t('menu.setting-industry-templates-master'),
              },
              {
                to: '/setting/news',
                icon: 'mdi-bell-cog',
                text: this.$i18n.t('menu.setting-news'),
              },
              {
                group: true,
                subGroup: true,
                title: this.$i18n.t('menu.setting-master-maintenance'),
                show: this.checkShowMenu('/setting/mst/'),
                items: [
                  {
                    to: '/setting/mst/master-register-csv-export',
                    text: this.$i18n.t(
                      'menu.setting-mst-master-register-csv-export'
                    ),
                  },
                  {
                    to: '/setting/mst/retail-electricity-providers',
                    text: this.$i18n.t(
                      'menu.setting-mst-retail-electricity-providers'
                    ),
                  },
                  {
                    to: '/setting/mst/retail-electricity-providers-emission',
                    text: this.$i18n.t(
                      'menu.setting-mst-retail-electricity-providers-emission'
                    ),
                  },
                  {
                    to: '/setting/mst/emissions-unit-value-master',
                    text: this.$i18n.t(
                      'menu.setting-mst-emissions-unit-value-master'
                    ),
                  },
                  {
                    to: '/setting/mst/transport-master',
                    text: this.$i18n.t('menu.setting-mst-transport-master'),
                  },
                ],
              },
            ],
          },
          {
            icon: 'mdi-logout',
            text: this.$i18n.t('menu.logout'),
            click: this.doLogout,
          }
        )

        return _links
      },
    },
  },
  watch: {
    showNotification(value) {
      if (!value) {
        this.getNews()
      }
    },
    '$route.path': {
      handler: function (value) {
        this.getNews()
      },
    },
  },
  methods: {
    ...mapMutations('app', ['SET_TOKEN', 'TOGGLE_NOTIFICATION']),
    ...mapActions('app', ['doSetDrawer', 'getNews', 'doLogout']),
    ...mapActions('item', {
      doSetItemSearchCriteria: 'doSetItemSearchCriteria',
      doSetItemCurrentPage: 'doSetCurrentPage',
    }),
    ...mapActions('request', {
      doSetRequestSearchCriteria: 'doSetRequestSearchCriteria',
      doSetRequestCurrentPage: 'doSetCurrentPage',
    }),
    cancel() {
      this.dialog = false
      this.clearInput()
      this.$refs.obs.reset()
    },
    clearInput() {
      this.newPassword = ''
      this.oldPassword = ''
      this.confirmedPassword = ''
    },
    checkActiveDrawer(to) {
      let result = false
      let routeString = this.$route.path

      result = routeString == to

      return result
    },
    async changePassword() {
      try {
        this.newPassword = ''
        this.oldPassword = ''
        this.dialog = false
        this.$refs.notification.open(
          'パスワード変更',
          'パスワードを変更しました',
          { color: 'success', width: '50%' }
        )
      } catch (e) {
        console.log(e)
        this.dialog = false
        this.$refs.notification.open(
          'パスワード変更',
          'パスワードの変更に失敗しました',
          { color: 'warning', width: '50%' }
        )
      } finally {
        this.clearInput()
        this.$refs.obs.reset()
      }
    },
  },
}
</script>
<style scoped>
::v-deep .v-list-item__title {
  font-size: 14px !important;
}

.notification {
  position: absolute;
  left: 15px;
  bottom: 60px;
}

.notification .v-expansion-panel {
  max-height: 400px;
  overflow-y: auto;
  background-color: #fef3eb;
}

.label {
  background-color: #fef3eb;
  border-radius: 2px;
  align-self: flex-start;
  flex-shrink: 0;
  font-size: 1rem;
}

.noti-content {
  word-break: break-word;
}

.btn-fab {
  bottom: 10px !important;
  width: 55px !important;
  height: 55px !important;
  padding: 0.75rem;
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

.ringing {
  animation-timing-function: ease-in-out;
  animation-name: ring;
  animation-iteration-count: infinite;
  animation-duration: 4s;
  animation-delay: 0.7s;
  transform-origin: 50% 4px;
}

.font-zise-bell {
  font-size: 34px !important;
}
</style>
